import React, { useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Influencer } from '../Assets';

const BecomeInflencer = () => {
    const form = useRef();
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState({
        fullname: "",
        email: "",
        contact: "",
        city: "",
        socialLink: "",
        noFollower: "",
        message: "",
    });

    const sendEmail = async (e) => {
        e.preventDefault();

        axios.post('https://webrangerstalent.com/mail/mail.php', {
            ...data
        }
        )
            .then(function (response) {
                console.log(response.data.message);

                if (response.data.status === true) {
                    setSubmit(true);
                    axios.post('https://script.google.com/macros/s/AKfycbz2M9EbZMyZ3A-c2hwbgj-d06IY2IYwSrVnAKXt9MSr5hgxeDULC-F3_EhzabqRB8bN/exec', {
                        name: data.fullname,
                        email: data.email,
                        mobile: data.contact,
                        city: data.city,
                        link: data.socialLink,
                        noOfFollower: data.socialLink,
                    },{
                        mode:'no-cors',
                        cache:'no-cache',
                        credentials:'omit',
                        headers:{
                            'Content-Type':'text/plain'
                        },
                        crossDomain:true,
                        redirect:'follow'
                    } 
                    )
                        .then(function (response) {
                            console.log(response.data.message);

                            if (response.data.status === true) {
                                setSubmit(true);

                            }
                            else if (response.data.status === false) {
                                setSubmit(false);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });




                }
                else if (response.data.status === false) {
                    setSubmit(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    };


    const formdata = (e) => {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata);
    }
    return (
        <>
            <Helmet>
                <title>Become Influencer - Webrangers Talent Management</title>
            </Helmet>
            <section className='contact-container influencer-container'>
                <div className='container-fluid'>


                    <div className='row'>
                        <div className='col-md-6 col-12'>
                        <img src={Influencer} alt="Influencer" className='img-fluid'/>

                            <h1 className='heading'>Join The WebRangers Family</h1>
                            <h1 className='subheading'>Our brand users are constantly looking for nano, micro, macro, and mega influencers for paid collaborations. </h1>
                            <h1 className='subheading'>Want to increase your profile visibility and monetize your feed?</h1>
                            <h1 className='subheading'>Then, join the WebRangers Family today!</h1>
                            <h1 className='subheading'>Email at <a href='mailto:talents@webrangerstalent.com'>talents@webrangerstalent.com</a></h1>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='contactForm'>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="fullname" name="fullname" onChange={(e) => formdata(e)} value={data.fullname} placeholder="Full Name" />
                                        <label for="fullname">Full Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="email" name="email" onChange={(e) => formdata(e)} value={data.email} placeholder="name@example.com" />
                                        <label for="email">Email address</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="contact" name="contact" onChange={(e) => formdata(e)} value={data.contact} placeholder="Mobile Number" />
                                        <label for="contact">Mobile Number</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="city" name="city" onChange={(e) => formdata(e)} value={data.city} placeholder="Mobile Number" />
                                        <label for="city">City</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="socialLink" name="socialLink" onChange={(e) => formdata(e)} value={data.socialLink} placeholder="Mobile Number" />
                                        <label for="socialLink">Social Platform Link ( example Youtube, Instagram)</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="noFollower" name="noFollower" onChange={(e) => formdata(e)} value={data.noFollower} placeholder="Mobile Number" />
                                        <label for="noFollower">No. of Followers / Subscribers on the Social platform</label>
                                    </div>
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Leave a comment here" id="message" name='message' onChange={(e) => formdata(e)} value={data.message}></textarea>
                                        <label for="message">Message</label>
                                    </div>
                                    <button type='submit' className='btn btn-primary'>Submit</button>
                                    <p>{isSubmit ? 'Mail Sent Successfully!' : ''}</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BecomeInflencer;