import React, { useRef , useState} from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';


const Contact = () => {
    const form = useRef();
    const [isSubmit, setSubmit] = useState(false);
    const [data, setData] = useState({
        fullname: "",
        email: "",
        contact: "",
        message: "",
    });

    const sendEmail = (e) => {
        e.preventDefault();

        axios.post('https://webrangerstalent.com/mail/mail.php', {
            ...data
        }
        )
            .then(function (response) {
                console.log(response.data.message);

                if (response.data.status === true) {
                    setSubmit(true);
                }
                else if (response.data.status === false) {
                    setSubmit(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const formdata = (e) => {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata);
    }
    return (
        <>
            <Helmet>
                <title>Contact Us - Webrangers Talent Management</title>
            </Helmet>
            <section className='contact-listing teamsec'>
                <h1 className='heading'>We're a call away.  <span className='stroke-ani'><span>Say Hi.<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9"></path></svg></span></span></h1>
                <div className='container'>
                    <div className=' row'>
                        <div className='col-md-4 col-12'>
                            <div className='contact-list'>
                                <h3 className=''>Become Client</h3>
                                <p>+91  9833310016</p>
                                <p>Pushplata@webrangerstalent.com</p>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='contact-list'>
                                <h3 className=''>Press Inquiries</h3>
                                <p>+91 9833340016</p>
                                <p>Pushplata@webrangerstalent.com</p>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='contact-list'>
                                <h3 className=''>Join Webrangers</h3>
                                <p>+91 9833340016</p>
                                <p>Pushplata@webrangerstalent.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contact-container'>
                <div className='container-fluid'>
                    <h1 className='heading'>Let's Talk about your Idea</h1>
                    <div className='row'>
                        <div className='col-md-6 col-12'>
                            <div className='content'>

                                <div className='list'>
                                    <h4 className='contact-head'>Our Location</h4>
                                    <p>302, Off Link Rd, A-4, Veera Desai Industrial Estate, Andheri West, Mumbai, Maharashtra 400053</p>
                                </div>
                                <div className='list'>
                                    <h4 className='contact-head'>Email Adress</h4>
                                    <p>neha@webrangerstalent.com<br />pushplata@webrangerstalent.com</p>
                                </div>
                                <div className='list'>
                                    <h4 className='contact-head'>Contact Number</h4>
                                    <p>+91 9833340016</p> <p>+91 9833310016</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div className='contactForm'>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="fullname" name="fullname" onChange={(e) => formdata(e)} placeholder="Full Name" />
                                        <label for="fullname">Full Name</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="email" name="email"onChange={(e) => formdata(e)}  placeholder="name@example.com" />
                                        <label for="email">Email address</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="contact" name="contact"onChange={(e) => formdata(e)}  placeholder="Mobile Number" />
                                        <label for="contact">Mobile Number</label>
                                    </div>
                                    <div className="form-floating">
                                        <textarea className="form-control" placeholder="Leave a comment here" onChange={(e) => formdata(e)} id="message" name='message'></textarea>
                                        <label for="message">Comments</label>
                                    </div>
                                    <button type='submit' className='btn btn-primary'>Submit</button>
                                    <p>{isSubmit? 'Mail Sent Successfully!' : ''}</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;