import React from 'react';

const ErrorPage = () =>{
    return(
        <>
        <div className='error-page'>
            <div className='container'>
                <div className='error-container'>
                <h1>404</h1>
                <p>Page Not Found! </p>
                </div>

            </div>
        </div>
        </>
    )
}

export default ErrorPage;