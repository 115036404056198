import React from 'react';
import { Helmet } from "react-helmet";
import { Reel, TuttGaya, Reel1, Moj, Saregma, Josh, IndieMusic, Habit, BeyondBollywood, DelhiPolice, GK, WhistleBaja, WhistleBaja1, Fit, KalaChasma, BombayNo3, GravityZero, DanishDesigner, UmraoJaan, RG, MerchantRecord, DoGhoont, TuttGaya1 } from '../Assets/';


const Case = () => {
    return (
        <>
            <Helmet>
                <title>Work- Webrangers Talent Management</title>
            </Helmet>
            <div className='aimSec workSec work-page'>
                <div className='container-fluid'>
                    <div className='row conatiner'>
                        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-4'>
                            <div className='device-wrapper'>
                                <div className='device'>
                                    <video loop autoPlay muted className='screen'>
                                        <source src={Reel} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='content'>
                                    <h5 className='para-heading'>
                                        Whistle Baja Campaign with Tiger Shroff and Young Influencers in association with SaReGaMa
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-4'>
                            <div className='device-wrapper'>
                                <div className='device'>
                                    <video loop autoPlay muted className='screen'>
                                        <source src={TuttGaya} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='content'>
                                    <h5 className='para-heading'>
                                        Tutt Gaya Campaign with Shantanu Maheshwari and Ashnoor Kaur in association with SaReGaMa
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-4'>
                            <div className='device-wrapper'>
                                <div className='device'>
                                    <video loop autoPlay muted className='screen'>
                                        <source src={Reel1} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='content'>
                                    <h5 className='para-heading'>
                                        Whistle Baja Campaign with Dancing Super Star Sanchit, Gunjan, Florina & Amitkumar in association with SaReGaMa
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='casePlat'>
                <h1 className='heading'>Platforms we use</h1>
                <div className='container'>
                    <div className='platform-row'>
                        <div className='icon'>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 23.101 23.101"><g>
                                <path d="M8.258,4.458c0-0.144,0.02-0.455,0.06-0.931c0.043-0.477,0.223-0.976,0.546-1.5c0.32-0.522,0.839-0.991,1.561-1.406 C11.144,0.208,12.183,0,13.539,0h3.82v4.163h-2.797c-0.277,0-0.535,0.104-0.768,0.309c-0.231,0.205-0.35,0.4-0.35,0.581v2.59h3.914 c-0.041,0.507-0.086,1-0.138,1.476l-0.155,1.258c-0.062,0.425-0.125,0.819-0.187,1.182h-3.462v11.542H8.258V11.558H5.742V7.643 h2.516V4.458z" /><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                            </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                            </svg>
                        </div>
                        <div className='icon'>
                            <svg width="24px" height="24px" viewBox="0 0 24 24">
                                <g>
                                    <path style={{ fill: 'none' }} d="M0 0h24v24H0z" />
                                    <path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z" />
                                </g>
                            </svg>
                        </div>
                        <div className='icon'>
                            <svg version="1.1" id="Capa_1" x="0px" y="0px" width="33.88px" height="33.88px" viewBox="0 0 33.88 33.88">
                                <g>
                                    <path d="M30.414,10.031c0.014,0.297,0.021,0.595,0.021,0.897c0,9.187-6.992,19.779-19.779,19.779c-3.928,0-7.58-1.149-10.657-3.123 c0.546,0.063,1.099,0.095,1.658,0.095c3.26,0,6.254-1.107,8.632-2.974c-3.039-0.058-5.607-2.065-6.491-4.828 c0.424,0.082,0.858,0.125,1.308,0.125c0.635,0,1.248-0.084,1.83-0.244c-3.177-0.639-5.576-3.448-5.576-6.815 c0-0.029,0-0.058,0-0.087c0.939,0.521,2.01,0.833,3.15,0.869C2.646,12.48,1.419,10.35,1.419,7.938c0-1.274,0.343-2.467,0.94-3.495 c3.427,4.206,8.552,6.973,14.327,7.263c-0.117-0.509-0.18-1.038-0.18-1.584c0-3.838,3.112-6.949,6.953-6.949 c1.998,0,3.805,0.844,5.07,2.192c1.582-0.311,3.072-0.89,4.416-1.686c-0.521,1.624-1.621,2.986-3.057,3.844 c1.406-0.166,2.746-0.54,3.991-1.092C32.949,7.826,31.771,9.05,30.414,10.031z" /></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                            </svg>
                        </div>
                        <div className='icon'>
                            <svg width="16px" height="16px" viewBox="0 0 16 16">
                                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                            </svg>
                        </div>
                        {/* <div className='icon'>
                            <svg width="24px" height="24px" viewBox="0 0 24 24">
                                <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
                            </svg>
                        </div> */}
                        <div className='icon'>
                            <svg width="32px" height="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <path d="M 8.6425781 4 C 7.1835781 4 6 5.181625 6 6.640625 C 6 8.099625 7.182625 9.3085938 8.640625 9.3085938 C 10.098625 9.3085938 11.283203 8.099625 11.283203 6.640625 C 11.283203 5.182625 10.101578 4 8.6425781 4 z M 21.535156 11 C 19.316156 11 18.0465 12.160453 17.4375 13.314453 L 17.373047 13.314453 L 17.373047 11.310547 L 13 11.310547 L 13 26 L 17.556641 26 L 17.556641 18.728516 C 17.556641 16.812516 17.701266 14.960938 20.072266 14.960938 C 22.409266 14.960937 22.443359 17.145609 22.443359 18.849609 L 22.443359 26 L 26.994141 26 L 27 26 L 27 17.931641 C 27 13.983641 26.151156 11 21.535156 11 z M 6.3632812 11.310547 L 6.3632812 26 L 10.923828 26 L 10.923828 11.310547 L 6.3632812 11.310547 z" />
                            </svg>
                        </div>
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.06 303.53">
                                <path class="cls-1" d="M59.53,152.94H5.65c-5.86,0-5.65,7.3-5.65,7.3v30.34c0,8.83,1.85,16.69,3.18,25.42,12.71,83.84,100.35,87.53,100.35,87.53,47.3-.61,76.55-37.32,91.42-63.5a93.32,93.32,0,0,0,12.11-46.1V9.65C207.06.47,196.47,0,196.47,0H130.59L104.71,40.47h81.41l-55.53,87.06h37.65l-108,136.94L91.3,177.65l-41.42-.24Z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aboutPage servicePage workPage y-bg'>
                <div className='container-fluid'>
                    <h1 className='primary-heading Campaign-head'>Campaign</h1>
                    <div className='row Campaign-Wrok'>
                        <div className='col-md-4 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <img src={WhistleBaja1} alt="Whistle Baja" className="img-fluid" />
                                <div className='campaign-info'>
                                    <h3 className='heading'>Whistle Baja 2.0</h3>
                                    <p>Collaboration with actors / Influencers from Bollywood from the promotion of WHISTLE BAJA 2.0 from the movie HEROPANTI 2.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <img src={WhistleBaja} alt="Whistle Baja Dance Cover" className="img-fluid" />
                                <div className='campaign-info'>
                                    <h3 className='heading'>Whistle Baja 2.0 Dance Cover</h3>
                                    <p>Hip Hop Dance Battle between the reality show stars, battling on Saregama’s superhit track Whistle Baja 2.0 featuring Tiger Shroff spearheaded by Chintan Pavlankar & directed by Rajeev Goswami from WebRangers Talent Management.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <img src={TuttGaya1} alt="Tutt Gaya" className="img-fluid" />
                                <div className='campaign-info'>
                                    <h3 className='heading'>Tutt Gaya</h3>
                                    <p>Recent successful song promotion campaign with SaReGaMa featuring Shantanu Maheshwari & Ashnoor Kaur, collaborating with talented influencers.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <img src={Habit} alt="Habit" className="img-fluid" />
                                <div className='campaign-info'>
                                    <h3 className='heading'>Habit</h3>
                                    <p>A successful song promotion campaign with SaReGaMa featuring Siddharth Shukla & Shehnaaz Gill, collaborating with talented influencers.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <img src={DoGhoont} alt="Do Ghoont" className="img-fluid" />
                                <div className='campaign-info'>
                                    <h3 className='heading'>Do Ghoont</h3>
                                    <p>Collaboration with actors / Influencers from Bollywood from the promotion of Do Ghoont music album featuring Nia Sharma.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='clientRow'>
                <div className='container'>
                    <h1 className='heading'>Who we've worked with</h1>
                    <div className='row'>
                        <div className='col-md-2 col-6'>
                            <img src={DelhiPolice} alt="Delhi Police" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={Saregma} alt="Sa Re Ga Ma" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={IndieMusic} alt="IndieMusic" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={KalaChasma} alt="Kala Chasma" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={BombayNo3} alt="Bombay No3" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={RG} alt="Bombay No3" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={GravityZero} alt="Bombay No3" className='img-fluid' />
                        </div>

                        <div className='col-md-2 col-6'>
                            <img src={Josh} alt="Josh" className='img-fluid' />
                        </div>

                        <div className='col-md-2 col-6'>
                            <img src={Moj} alt="Moj" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={Fit} alt="Fit Fat" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={DanishDesigner} alt="Bombay No3" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={MerchantRecord} alt="Merchant Record" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={GK} alt="GK" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={BeyondBollywood} alt="Beyond Bollywood" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={UmraoJaan} alt="Bombay No3" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Case;