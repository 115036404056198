import { Chintan, Irfan, Danish, Ravi, Neha, Yamini, Vijiya, Sagar, Pushplata } from '../Assets/';
export const TeamData = [
    {
        'id': 1,
        'name': 'Chintan Pavlankar',
        'profile': Chintan,
        'position': 'Co-founder',
        'details': [
            'Chintan Pavlankar is a name synonym with digital marketing in India. As the Founder & CEO of WebRangers Entertainment, Chintan has achieved enormous success in providing digital marketing solutions to a multitude of clients. His long list of clientele includes about 220+ celebrities from Bollywood & Television industry varying from Salim-Sulaiman, Tiger Shroff, Jennifer Winget, Mohit Malik, Surbhi Jyoti, to name a few. His association with the best in the industry is remarkable and has led to a strong step-up as an individual achiever',
            <br />,
            <br />,
            'WebRangers Entertainment is an official partner with the leading social media platform that dominates the industry like Facebook, Instagram, Twitter and Youtube. Chintan has also been closely working with the social media platforms to onboard renowned celebrities on these platforms to curate unique contents. With Chintan’s trait of providing the best at the fastest, he has also been curating a suitable community of musicians/singers on the newly introduced social media platform Clubhouse; which has been attracting a lot of attention in the Social Media World. WebRangers has been one of the few agencies to review the pilot launch of the applications and key features of apps like Josh, Twitter, Facebook & Instagram. Chintan is all set to add another noteworthy achievement to his long list of accomplishments duly filled over the last 6 years, which is Webrangers Talent Management'
        ]
    },
    {
        'id': 2,
        'name': 'Danish Khan',
        'profile': Danish,
        'position': 'Co-founder',
        'details': [
            'Danish is a strong example of style, dedication and fitness to the youth and a source of entertainment to all his fans. Acting has always been his passion which is evidently seen on screen in Pyar Tune Kya Kiya and various MTV shows. He is a famous Instagram influencer with 450k+ followers. His creative videos on Josh are loved by his fans the most which have got him over 7M+ fans. He has been an endorser of several brands like BodyPharm, Daniel Wellington, RiteBite, to name a few.',
            <br />,
            <br />,
            'He’s been an active part of Lakme Fashion Week 2016 as a ramp model for the brand “Antaragni”. He has also featured in “Soch Reprise” an album which created a buzz among the audience. His passion for designing clothes lead to a grand launch of his own brand of ethnic men’s wear called “Danish Khan Designs” and there has been no turning back since then. His hard work and creative passion is widely appreciated. Being an influencer and having a strong impact in the digital world, helps the Team to understand the better of both worlds and hence be a better problem solving unified team.'
        ]
    }
    ,
    {
        'id': 3,
        'name': 'Irfan Siddiqui',
        'profile': Irfan,
        'position': 'Co-founder',
        'details': 'Being one of the founding partners of WebRangers Talent Management, Irfan Siddiqui had initially started off his journey by writing the mellifluous song Ashayein from the movie Iqbal. This was not only was a chart-topper, but it soon became an anthem for motivation. He went on to exhibit his lyrical excellence by writing hit songs for Hindi movies Fashion, Heroin, Chance Pe Dance and Kurbaan to name a few. He has also written songs for some of the leading music directors and composers, namely Salim-Sulaiman, Adnan Sami, Pritam and many more. His flair also gained high repute for writing songs of Pop albums for renowned artists like Adnan Sami, Shiamak Davar, Rajeev Goswami and Ganesh Hegde. With his panache in lyrics he wrote anthems for Indion Idol 4, and the first ever IPL to happen in 2009. His extensive profile also includes many ad jingles. His finesse for writing created resonance as he wrote for international artists and albums, namely Adnan Sami’s Ek Ladki Thi Diwani Si featuring Jermaine Jackson, and the Hindi version of High School Musical I and High School Musical II. He is also one of the producers for the only WESTEND Musical from India called Beyond Bollywood & Umrao Jaan Ada alongside Salim Sulaiman and Rajeev Goswami.'
    }
    ,
    {
        'id': 4,
        'name': 'Ravi Goswami',
        'profile': Ravi,
        'position': 'Co-founder',
        'details': [
            'Having been associated with production field for over seven years, Mr. Goswami has redefined smooth operations for every small-large scale event productions endeavored till date. Holding a Master’s degree in International Business Management from London, Mr. Goswami applies his discipline of business management with confidence, persistence and optimism towards successful show running and stage management for every in-house event produced. His experience with artists and creative minds is an added advantage used to its optimum. Many local and international runs have been performed under his strong leadership, like Namaste India – USA; Beyond Bollywood – India, Paris, Italy,Amsterdam, Germany, Netherlands, Belgium, UAE, Oman, Turkey; Umrao Jaan Ada – London and India to name a few. His proven expertise acquired him prized title of WOW Asia Awards for Show Running and Stage Management for our Production team as well',
            <br />,
            <br />,
            'Mr. Goswami has always been responsible to execute stage production and liaising successfully via his strong negotiation and problem solving abilities right from ideation of event objectives, pre-production arrangements, allocating resources, handling logistics, creating timelines and post-production evaluations. His involvement will add a new dimension to WebRangers Talent Management with the perspective of production, creativity, planning or executing a campaign. Since he has handled numerous talents himself as a part of his stage shows, events and musicals; hence managing them is an art that he has developed and excelled over a period of time.'
        ]
    },
    
    {
        'id': 8,
        'name': 'Vijaya Raut',
        'profile': Vijiya,
        'position': 'Accounts Head',
    },
    {
        'id': 7,
        'name': 'Pushplata Begani',
        'profile': Pushplata,
        'position': 'Manager-Media Partnerships',
    },
    {
        'id': 6,
        'name': 'Yamini Khanna',
        'profile': Yamini,
        'position': ['Content Strategist', <br />, '- Social Media'],
    },
    {
        'id': 9,
        'name': 'Sagar Maurya',
        'profile': Sagar,
        'position': ['Web Developer &', <br />, 'Graphic Designer'],
    },

];