import React from 'react';
import { Helmet } from "react-helmet";
import { About1 } from '../Assets/';
import { TeamData } from '../Components/teamData';


const About = () => {
    return (
        <>
            <Helmet>
                <title>About Us- Webrangers Talent Management</title>
            </Helmet>
            <div className='teamsec'>
                <h1 className='heading'>Who's <span className='stroke-ani'><span>Webrangers?<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9"></path></svg></span></span></h1>
            </div>
            <div className='aboutPage md-reverse'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-12 col-12'>
                            <div className='abt-content'>
                                <h1 className='primary-heading'>We partner your brand with the right influencers and launch it to the masses.</h1>
                                <p>WebRangers is one of a kind talent management company that associates with brands and talents offering them 360-degree solutions that foster them to create an excellent online presence which leads to an overall advancement. We at WebRangers just do not connect two parties we provide an overall experience that includes content creation, production and direction, graphics, cinematography, and a lot more.</p>
                                <p>With our in-house production company, we have successfully built a trusted community in the industry that steps in with their expertise, networks, and experience to mold talent and assist brands to flourish. With an experience of more than half a decade, we know exactly how to tap into the limitless power of social media and use it to propel the brand to the next level. WebRangers offers everything that an artist dreams for, so what are you waiting for? Connect with us today and become a part of our #WEBRANGERSFAMILY NOW!</p>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-12 col-12'></div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <img className='img-fluid mb-lg-0 mb-4' src={About1} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='aimSec'>
                <div className='container-fluid'>
                    <h1 className='primary-heading'>Our Aim 🎯</h1>
                    <div className='row'>
                        <div className='col-lg-6 col-12 mb-lg-0 mb-2'>
                            <div className='abt-content'>
                                <p>We live in the digital era where social media has triumphed all other traditional media forms. Viral is the new measure of success for any marketing campaign. We exactly know how to tap into the limitless power of social media and use it to propel the brand to next level.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 mb-lg-0 mb-2'>
                            <div className='abt-content'>
                                <p>We at WebRangers Talent Management do not limit ourselves to just providing the right talent, but also to help along the way to conceptualize & ideate a campaign/collaboration, and execute it to the best of our abilities for our clients offering a full circle of services ranging from creative to production support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aboutPage servicePage y-bg'>
                <div className='container-fluid'>
                    <h1 className='primary-heading'>What We Do</h1>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Talent Management</h3>
                                <p>We believe in walking together to walk further. As per this approach we constantly team-up with influencers & celebrities to get the best of the talents for promotion.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Influencer Marketing</h3>
                                <p>Getting the right personality to promote a brand of utmost importance. Here is where we come in. Our expertise at WebRangers Talent Management is to strike the right balance between the brand and the influencer and match the brands requirements with the pool of talent we have.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Social Media Marketing</h3>
                                <p>Getting the right personality to promote a brand of utmost importance. Here is where we come in. Our expertise at WebRangers Talent Management is to strike the right balance between the brand and the influencer and match the brands requirements with the pool of talent we have.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Audio & Video Production</h3>
                                <p>We live in the digital era where social media has triumphed all other traditional media forms. Viral is the new measure of success for any marketing campaign. We exactly know how to tap into the limitless power of social media and use it to propel the brand to next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aimSec'>
                <div className='container-fluid'>
                    <h1 className='heading text-center'>Our Rangers</h1>
                    <div className='row team-container'>
                        {
                            TeamData && TeamData.map(TeamData => {
                                return (
                                    <div className='col-lg-3 col-md-6 col-12'>
                                        <div className='team-content'>
                                            <img src={TeamData.profile} alt='' className='img-fluid' />
                                            <h5 className='primary-heading'>{TeamData.name}</h5>
                                            <p>{TeamData.position}</p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;