export const About1 = require('./Images/about.png');
export const Influencer = require('./Images/influencer.png');
export const Chintan = require('./Images/Chintan-v1.jpg');
export const Danish = require('./Images/Danish.jpg');
export const Irfan = require('./Images/Irfan.jpg');
export const Ravi = require('./Images/Ravi.jpg');
export const Neha = require('./Images/Neha.jpg');
export const Yamini = require('./Images/Yamini.jpg');
export const Vijiya = require('./Images/Vijiya.jpg');
export const Sagar = require('./Images/Sagar.jpg');
export const Pushplata = require('./Images/Pushplata.jpg');

export const Background = require('./Images/background.jpg');

export const TeamP = require('./Images/team.png');
export const CoverPage = require('./Images/coverpage.jpg');
export const Reel = require('./Video/reel.mp4');
export const Reel1 = require('./Video/reel-1.mp4');
export const TuttGaya = require('./Video/Tutt Gaya.mp4');


// /clientele Logo

export const GravityZero = require('./Images/logo/GravityZero.png');
export const DelhiPolice = require('./Images/logo/DP.png');
export const BombayNo3 = require('./Images/logo/BombayNo3.png');
export const Fit = require('./Images/logo/fitfat.png');
export const DanishDesigner = require('./Images/logo/DanishDesign.png');
export const GK = require('./Images/logo/GK.png');
export const Josh = require('./Images/logo/Josh.png');
export const KalaChasma = require('./Images/logo/KalaChasma.png');
export const Moj = require('./Images/logo/Moj.png');
export const Saregma = require('./Images/logo/Saregma.png');
export const IndieMusic = require('./Images/logo/IndieMusic.png');
export const BeyondBollywood = require('./Images/logo/BeyondBollywood.png');
export const UmraoJaan = require('./Images/logo/UmraoJaan.png');
export const RG = require('./Images/logo/RG.png');
export const MerchantRecord = require('./Images/logo/MerchantRecord.png');


// CAmpaign
export const WhistleBaja = require('./Images/Campaign/WhistleBaja.jpg');
export const WhistleBaja1 = require('./Images/Campaign/WhistleBaja1.jpg');
export const DoGhoont = require('./Images/Campaign/DoGhoont.jpg');
export const TuttGaya1 = require('./Images/Campaign/TuttGaya.jpg');
export const Habit = require('./Images/Campaign/Habit.jpg');

