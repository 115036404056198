import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Reel, TuttGaya, Reel1 , Background ,Moj, Saregma, Josh, IndieMusic, BeyondBollywood, DelhiPolice, GK,Fit, KalaChasma, BombayNo3, GravityZero, DanishDesigner, UmraoJaan, RG, MerchantRecord} from '../Assets/';
import { TeamData } from '../Components/teamData';

const Home = () => {

    return (
        <>
            <Helmet>
                <title> Webrangers Talent Management</title>
            </Helmet>
            {/* <div className='landingPage'>
                <video loop autoPlay muted>
                    <source src={Intro} type="video/mp4" />
                </video>
            </div> */}
            <div className='landingPage'>
                <img src={Background} alt='webrangertalent' className='img-fluid'/>
            </div>
            <div className='aboutPage y-bg'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-4 col-12'>
                            <div className='abt-content'>
                                <h1 className='heading'>For your dream,<br />We have a Team</h1>
                            </div>
                        </div>
                        <div className='col-md-8 col-12'>
                            <div className='abt-content'>
                                <p>We at WebRangers Talent Management are committed to collaborate and build an excellent rapport between the brands and the talent. We are a 360 degree solution to all your dreams providing effective advocacy solutions so as to deliver high-quality engagement for the brands as well as to the talents. We hail from a strong digital marketing background in association with our sister concern venture “WebRangers Entertainment”; that is instrumental in steering the social media presence of about 220+ celebrities and trusted to do so over the years. We have a bankable & multi-faceted talent base of well renowned celebrities and influencers onboard.</p>
                                <Link to='/about' className='navbtn'>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aimSec workSec'>
                <div className='container-fluid'>
                <h1 className='heading text-center'>Our Work</h1>
                    <div className='row conatiner'>
                        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-4'>
                            <div className='device-wrapper'>
                                <div className='device'>
                                    <video loop autoPlay muted className='screen'>
                                        <source src={Reel} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='content'>
                                    <h5 className='para-heading'>
                                        Whistle Baja Campaign with Tiger Shroff and Young Influencers in association with SaReGaMa
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-4'>
                            <div className='device-wrapper'>
                                <div className='device'>
                                    <video loop autoPlay muted className='screen'>
                                        <source src={TuttGaya} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='content'>
                                    <h5 className='para-heading'>
                                        Tutt Gaya Campaign with Shantanu Maheshwari and Ashnoor Kaur in association with SaReGaMa
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12 mb-lg-0 mb-4'>
                            <div className='device-wrapper'>
                                <div className='device'>
                                    <video loop autoPlay muted className='screen'>
                                        <source src={Reel1} type="video/mp4" />
                                    </video>
                                </div>
                                <div className='content'>
                                    <h5 className='para-heading'>
                                        Whistle Baja Campaign with Dancing Super Star Sanchit, Gunjan, Florina & Amitkumar in association with SaReGaMa
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aboutPage servicePage y-bg'>
                <div className='container-fluid'>
                    <h1 className='primary-heading text-center'>What We Do</h1>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Talent Management</h3>
                                <p>We believe in walking together to walk further. As per this approach we constantly team-up with influencers & celebrities to get the best of the talents for promotion.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Influencer Marketing</h3>
                                <p>Getting the right personality to promote a brand of utmost importance. Here is where we come in. Our expertise at WebRangers Talent Management is to strike the right balance between the brand and the influencer and match the brands requirements with the pool of talent we have.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Social Media Marketing</h3>
                                <p>Getting the right personality to promote a brand of utmost importance. Here is where we come in. Our expertise at WebRangers Talent Management is to strike the right balance between the brand and the influencer and match the brands requirements with the pool of talent we have.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12 mb-lg-0 mb-2'>
                            <div className='ser-content'>
                                <h3 className='heading'>Audio & Video Production</h3>
                                <p>We live in the digital era where social media has triumphed all other traditional media forms. Viral is the new measure of success for any marketing campaign. We exactly know how to tap into the limitless power of social media and use it to propel the brand to next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aimSec'>
                <div className='container-fluid'>
                    <h1 className='heading text-center'>Our Rangers</h1>
                    <div className='row team-container'>
                        {
                            TeamData && TeamData.map((TeamData) => {
                                return (
                                    <div className='col-lg-3 col-md-6 col-12 '>
                                        <div className='team-content'>
                                            <img src={TeamData.profile} alt='' className='img-fluid' />
                                            <h5 className='primary-heading'>{TeamData.name}</h5>
                                            <p>{TeamData.position}</p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='clientRow'>
                <div className='container'>
                    <h1 className='heading'>Who we've worked with</h1>
                    <div className='row'>
                        <div className='col-md-2 col-6'>
                            <img src={DelhiPolice} alt="Delhi Police" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={Saregma} alt="Sa Re Ga Ma" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={IndieMusic} alt="IndieMusic" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={KalaChasma} alt="Kala Chasma" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={BombayNo3} alt="Bombay No3" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={RG} alt="Bombay No3" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={GravityZero} alt="Bombay No3" className='img-fluid' />
                        </div>

                        <div className='col-md-2 col-6'>
                            <img src={Josh} alt="Josh" className='img-fluid' />
                        </div>

                        <div className='col-md-2 col-6'>
                            <img src={Moj} alt="Moj" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={Fit} alt="Fit Fat" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={DanishDesigner} alt="Bombay No3" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={MerchantRecord} alt="Merchant Record" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={GK} alt="GK" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={BeyondBollywood} alt="Beyond Bollywood" className='img-fluid' />
                        </div>
                        <div className='col-md-2 col-6'>
                            <img src={UmraoJaan} alt="Bombay No3" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;