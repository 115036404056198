import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/Images/logo_b.svg';
import Facebook from '../Assets/Images/facebook.svg';
import Linkedin from '../Assets/Images/linkedin.svg';
import Twitter from '../Assets/Images/twitter.svg';
import Instagram from '../Assets/Images/instagram.svg';

const Footer = () => {
    return (
        <>
            <footer>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-4 col-12'>
                            <div className='footer-logo'>
                                <img className='img-fluid' src={Logo} alt='Webranger Talent Managment' />
                            </div>
                            <p>Connect with us today and become a part of our<br /><b>WEBRANGERS</b> FAMILY NOW!</p>
                            <div className='footer-social-icon-list'>
                                <div className='icon'>
                                    <i className="fa-brands fa-facebook-f"></i>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 col-12'>
                            <div className='footer-list'>
                                <h3 className='footer-heading'>
                                    Quick Link
                                </h3>
                                <ul className='footer-items'>
                                    <li>
                                        <Link className='footer-link' to='/'>Home</Link>
                                    </li>
                                    <li>
                                        <Link className='footer-link' to='/about'>About</Link>
                                    </li>
                                    <li>
                                        <Link className='footer-link' to='/team'>Team</Link>
                                    </li>
                                    <li>
                                        <Link className='footer-link' to='/contact'>Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-2 col-12'>
                            <h3 className='footer-heading'>
                                Services
                            </h3>
                            <ul className='footer-items'>
                                <li>
                                    <p className='footer-link' >Talent Management</p>
                                </li>
                                <li>
                                    <p className='footer-link' >Influencer Marketing</p>
                                </li>
                                <li>
                                    <p className='footer-link'>Social Media Marketing</p>
                                </li>
                                <li>
                                    <p className='footer-link'>Audio & Video Production</p>
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-2 col-12'>
                            <h3 className='footer-heading'>
                                CONNECT
                            </h3>
                            <div className='footer-social-link'>
                                <a href='https://www.facebook.com/WebRangers-Talent-Management-109286951446976/' rel="noreferrer" target='_blank' className='icon'>
                                    <img src={Facebook} alt='' className='img-fluid' />
                                </a>
                                <a href='https://www.instagram.com/webrangerstalent/' rel="noreferrer" target='_blank' className='icon'>
                                    <img src={Instagram} alt='' className='img-fluid' />
                                </a>
                                <a href='https://twitter.com/WebrangersM' rel="noreferrer" target='_blank' className='icon'>
                                    <img src={Twitter} alt='' className='img-fluid' />
                                </a>
                                <a href='https://in.linkedin.com/company/webrangers-talent-management' rel="noreferrer" target='_blank' className='icon'>
                                    <img src={Linkedin} alt='' className='img-fluid' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='copyright'>
                <p className='container text-center'>© 2022 Webrangers Talent Management</p>
            </div>
        </>
    );
}

export default Footer;