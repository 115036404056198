import React from 'react';
import { Helmet } from "react-helmet";
import { TeamP } from '../Assets/';
import { TeamData } from '../Components/teamData';


const Team = () => {
    return (
        <>
            <Helmet>
                <title>Team - Webrangers Talent Management</title>
            </Helmet>
            <div className='teamPage'>
                <div className='teamsec'>
                    <h1 className='heading'>It All Starts With An <span className='stroke-ani'><span>Incredible Team<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9"></path></svg></span></span></h1>
                </div>
                <div class='team-wrappper'>
                    <img src={TeamP} alt='Rangers' className='img-fluid' />
                </div>
            </div>
            {
                TeamData && TeamData.slice(0,4).map(TeamData => {
                    return (
                        <div className='team-listing mb-4'>
                            <div className='container-fluid'>
                                <div className='team-content'>
                                    <div className='team-profile'>
                                        <img src={TeamData.profile} alt='Rangers' className='img-fluid' />
                                    </div>
                                    <div className='team-details'>
                                        <h3 className='heading'>{TeamData.name}</h3>
                                        <p className='team-position'>{TeamData.position}</p>
                                        <p className='team-data'>{TeamData.details}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </>
    )
}
export default Team;