import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import Logo from '../Assets/Images/logo.svg';

const Header = () =>{
    const [menuActive, setmenuActive] = useState(false);

    const toggleMenu = ()=>{
        setmenuActive(!menuActive);
    }
    const navLinkStyle = ({isActive})=>{
        return{
            color:isActive? '#f7d813': '#FFF5BA',
        }
    }
    return(
       <header>
            <div className='navbar navbar-expand-lg'>
                <div className='container-fluid'>
                    <NavLink className='navbar-brand' to='/'>
                        <img className='img-fluid' src={Logo} alt='Webrangers Talent Managment'/>
                    </NavLink>
                  <div className='hamburger-container' onClick={toggleMenu}>
                    <div className={menuActive? 'hamburger active': 'hamburger'}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                  </div>
                    <ul className={menuActive? 'navbar-nav ms-auto mb-2 mb-lg-0 active':'navbar-nav ms-auto mb-2 mb-lg-0'}>
                        <li className='nav-item'>
                            <NavLink className='nav-link' style={navLinkStyle} to='/' onClick={toggleMenu}>Home</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' style={navLinkStyle} to='/about' onClick={toggleMenu}>About Us</NavLink>
                        </li>
                        
                        <li className='nav-item'>
                            <NavLink className='nav-link' style={navLinkStyle} to='/work' onClick={toggleMenu}>Work</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' style={navLinkStyle} to='/becomeinfluencer' onClick={toggleMenu}>Become Influencer</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' style={navLinkStyle} to='/contact' onClick={toggleMenu}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
       </header>
    );
}

export default Header;