import React from 'react';
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import './App.scss';
import Footer from './Components/Footer';
import Header from './Components/Header';
import About from './Pages/About';
import BecomeInflencer from './Pages/BecomeInfluencer';
import Case from './Pages/Case';
import Contact from './Pages/Contact';
import ErrorPage from './Pages/ErrorPage';
import Home from './Pages/Home';
import Team from './Pages/Team';

function App() {
  return (
    <div className="App">
     <Router>
       <Header/>
       <Routes>
         <Route path='/' element={<Home/>}></Route>
         <Route path='/work' element={<Case/>}></Route>
         <Route path='/about' element={<About/>}></Route>
         <Route path='/contact' element={<Contact/>}></Route>
         <Route path='/becomeinfluencer' element={<BecomeInflencer/>}></Route>
         <Route path='/team' element={<Team/>}></Route>
         <Route path='*' element={<ErrorPage/>}></Route>
       </Routes>
       <Footer/>
     </Router>
    </div>
  );
}

export default App;
